import styled from '@emotion/styled'
import { Box } from '@mui/system'

const Spacer = styled(Box)({
  flexGrow: 1,
  justifySelf: 'stretch',
  alignSelf: 'stretch',
})

export default Spacer
