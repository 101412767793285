import createStyle from "@/utils/createStyle";

const styles = createStyle({
  input: {
    width: 0,
    height: 0,
    display: "none"
  },
  container: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "start",
    alignItems: "center",
    px: 2,
    py: 2.25,
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: 2,
    background: "#EBF8F1",
    border: "2px dashed #B8EFD4",
    gap: 1
  },
  imageContainer: {
    width: "calc(100% - 16px)",
    height: "calc(100% - 16px)",
    borderRadius: "0.5rem",
    position: "absolute",
    overflow: "hidden"
  },
  deleteIcon: {
    color: (th) => th.palette.primary.main,
    width: "1.5rem",
    height: "1.5rem",
    position: "absolute"
  },
  uploadIcon: {
    color: "primary.main",
    fontSize: 18
  },
  uploadIconImg: {
    width: "3rem",
    height: "3rem",
    color: "#A9A9AA",
    fontSize: 35
  },
  blackLayout: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "#fff"
  },
  previewImage: {
    width: "35%",
    height: "35%",
    objectFit: "contain",
    borderRadius: 2
  }
});

export default styles;
