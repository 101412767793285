import { Box, Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { FC } from "react";
import styles from "./styles";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AddIcon from "@mui/icons-material/Add";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Camera from "../Camera";
import VariantModal from "./VariantModal";
import useLogic from "./useLogic";

export type Variants = "camera" | "upload";

export interface ImageUploaderProps {
  value?: File | string | null;
  onChange?: (image: { file: File; url: string } | null) => void;
  title?: string;
  icon?:
    | (OverridableComponent<SvgIconTypeMap<object, "svg">> & {
        muiName: string;
      })
    | string;
  variant?: Variants[] | Variants;
  cameraProps?: Omit<
    Parameters<typeof Camera>[0],
    "open" | "setOpen" | "onSubmit"
  >;
}

const ImageUploader: FC<ImageUploaderProps> = (props) => {
  const {
    value,
    onChange,
    title,
    variant = "upload",
    icon: Icon = AddIcon,
    cameraProps = {}
  } = props;

  const {
    inputRef,
    isCameraOpen,
    setIsCameraOpen,
    isSelectVariantOpen,
    setIsSelectVariantOpen,
    variantsList,
    imageUrl,
    onInputChangeHandler,
    onContainerClicked,
    openCamera,
    openUpload,
    onChangeCaller
  } = useLogic({
    onChange: onChange,
    value: value,
    variant: variant
  });

  return (
    <>
      <Box
        component="input"
        ref={inputRef}
        display="none"
        value=""
        type="file"
        onChange={onInputChangeHandler}
      />

      <Stack component="div" onClick={onContainerClicked} sx={styles.container}>
        {typeof imageUrl === "string" && (
          <>
            <Stack sx={styles.imageContainer}>
              <Box
                component="img"
                sx={styles.previewImage}
                src={imageUrl}
                alt="image"
              />
            </Stack>
            <DeleteRoundedIcon sx={styles.deleteIcon} />
            <Box sx={styles.blackLayout} />
          </>
        )}
        {typeof imageUrl !== "string" && (
          <>
            {!!Icon && typeof Icon !== "string" && (
              <Icon sx={styles.uploadIcon} />
            )}

            {!!Icon && typeof Icon === "string" && (
              <Box
                sx={styles.uploadIconImg}
                component="img"
                src={Icon}
                alt="cover icon"
              />
            )}

            {typeof title === "string" && (
              <Typography
                color="#6E6E6E"
                fontWeight={400}
                fontSize={14}
                textAlign="center"
              >
                {title}
              </Typography>
            )}
          </>
        )}
      </Stack>

      {variantsList.includes("camera") && (
        <Camera
          {...cameraProps}
          open={isCameraOpen}
          setOpen={setIsCameraOpen}
          onSubmit={(newCameraImage) => {
            if (newCameraImage.file && newCameraImage.dataUrl) {
              onChangeCaller(newCameraImage.file);
            }
          }}
        />
      )}

      <VariantModal
        open={isSelectVariantOpen}
        setOpen={setIsSelectVariantOpen}
        openCamera={openCamera}
        variantsList={variantsList}
        openUpload={openUpload}
      />
    </>
  );
};

export default ImageUploader;
