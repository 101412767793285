import useAppSelector from "@/hooks/useAppSelector";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

const leasingSlice = createSlice({
  name: "leasing-slice",
  initialState: {
    isImageDrawerOpen: false,
    imageUrl: "",
    imageId: "",
    orderId: ""
  },
  reducers: {
    setImageDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isImageDrawerOpen = action.payload;
    },
    setImageUrl(state, action: PayloadAction<string>) {
      state.imageUrl = action.payload;
    },
    setImageId(state, action: PayloadAction<string>) {
      state.imageId = action.payload;
    },
    setOrderId(state, action: PayloadAction<string>) {
      state.imageId = action.payload;
    }
  }
});

export const leasingReducer = leasingSlice.reducer;
export const leasingActions = leasingSlice.actions;

export const useLeasingImage = () => {
  const dispatch = useDispatch();
  const { imageId, imageUrl, isImageDrawerOpen, orderId } = useAppSelector(
    (state) => state.leasing
  );

  const setOrderId = useCallback(
    (value: string) => {
      dispatch(leasingActions.setOrderId(value));
    },
    [dispatch]
  );

  const setImageId = useCallback(
    (value: string) => {
      dispatch(leasingActions.setImageId(value));
    },
    [dispatch]
  );
  const setImageUrl = useCallback(
    (value: string) => {
      dispatch(leasingActions.setImageUrl(value));
    },
    [dispatch]
  );
  const setImageDrawerOpen = useCallback(
    (value: boolean) => {
      dispatch(leasingActions.setImageDrawerOpen(value));
    },
    [dispatch]
  );

  return {
    imageId,
    imageUrl,
    isImageDrawerOpen,
    orderId,
    setOrderId,
    setImageId,
    setImageUrl,
    setImageDrawerOpen
  };
};
