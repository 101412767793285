import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  SvgIconTypeMap,
  Typography
} from "@mui/material";
import { FC, ReactNode, useRef } from "react";
import styles from "./styles";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Camera from "../Camera";
import VariantModal from "./VariantModal";
import useLogic from "./useLogic";
import { Upload } from "@mui/icons-material";
import { UploadLeasingFileResponse } from "@/services/api/leasing/requests";
import LinearProgressWithLabel from "../LinearProgressWithLabel";
import Close from "@mui/icons-material/Close";

export type Variants = "camera" | "upload";

export interface NewImageUploaderProps {
  value?: File | string | null;
  onChange?: (image: { file: File; url: string } | null) => void;
  onImageSelect?: (image: File | null) => void;
  onUplaodSucced?: (file: UploadLeasingFileResponse | null) => void;
  title?: string;
  icon?:
    | (OverridableComponent<SvgIconTypeMap<object, "svg">> & {
        muiName: string;
      })
    | string;
  variant?: Variants[] | Variants;
  cameraProps?: Omit<
    Parameters<typeof Camera>[0],
    "open" | "setOpen" | "onSubmit"
  >;
  children?: ReactNode;
  scope?: "leasing-pose-payments" | "leasing-documents";
  customView?: ReactNode;
}

const NewImageUploader: FC<NewImageUploaderProps> = (props) => {
  const {
    value,
    onChange,
    title,
    variant = "upload",
    icon: Icon = Upload,
    cameraProps = {},
    scope = "leasing-documents",
    onUplaodSucced,
    onImageSelect
  } = props;

  const {
    inputRef,
    isCameraOpen,
    setIsCameraOpen,
    isSelectVariantOpen,
    setIsSelectVariantOpen,
    variantsList,
    imageUrl,
    onInputChangeHandler,
    onContainerClicked,
    openCamera,
    openUpload,
    onChangeCaller,
    uploadPerecentage,
    clearFile,
    valueState
  } = useLogic(
    {
      onChange,
      value,
      variant,
      onUplaodSucced
    },
    scope,
    onImageSelect
  );

  return (
    <>
      <Box
        component="input"
        ref={inputRef}
        display="none"
        value=""
        type="file"
        onChange={onInputChangeHandler}
        accept="image"
      />
      {props.customView ? (
        <Stack onClick={onContainerClicked}>{props.customView}</Stack>
      ) : (
        <Stack
          component="div"
          onClick={onContainerClicked}
          sx={{
            ...styles.container,
            ...((imageUrl || valueState) && {
              background: "#fff",
              border: "none",
              p: 0,
              borderRadius: 0
            })
          }}
        >
          {imageUrl || valueState ? (
            <Stack direction={"row"} gap={1}>
              <Stack width={"100%"} gap={1} alignSelf={"end"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <IconButton
                      sx={{
                        width: "min-content",
                        height: "min-content",
                        alignSelf: "end"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        clearFile();
                      }}
                    >
                      <Close
                        sx={{
                          alignSelf: "end",
                          width: "18px",
                          height: "18px"
                        }}
                      />
                    </IconButton>
                    <Typography fontSize={12}>{uploadPerecentage}%</Typography>
                  </Stack>
                </Stack>
                <LinearProgress
                  sx={{ direction: "rtl", transform: "rotateY(180deg)" }}
                  variant="determinate"
                  value={uploadPerecentage}
                />
              </Stack>
              <Box
                component="img"
                sx={styles.previewImage}
                src={imageUrl || (valueState as string)}
                alt="image"
              />
            </Stack>
          ) : (
            <>
              <Icon sx={styles.uploadIcon} />
              {typeof title === "string" && (
                <Typography
                  color="primary"
                  fontWeight={400}
                  fontSize={14}
                  textAlign="center"
                >
                  {title}
                </Typography>
              )}
            </>
          )}
        </Stack>
      )}

      {variantsList.includes("camera") && (
        <Camera
          {...cameraProps}
          open={isCameraOpen}
          setOpen={setIsCameraOpen}
          onSubmit={(newCameraImage) => {
            if (newCameraImage.file && newCameraImage.dataUrl) {
              onChangeCaller(newCameraImage.file);
            }
          }}
        />
      )}

      <VariantModal
        open={isSelectVariantOpen}
        setOpen={setIsSelectVariantOpen}
        openCamera={openCamera}
        variantsList={variantsList}
        openUpload={openUpload}
      />
    </>
  );
};

export default NewImageUploader;
