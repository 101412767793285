import NewImageUploader from "@/components/NewImageUploader";
import { ErDocument } from "@/services/api/leasing/detail";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Divider,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

interface Props {
  docs: ErDocument[];
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  onSubmit: (data: [string, string][]) => void;
}

function FinalDocs({ open, onClose, onOpen, onSubmit, docs }: Props) {
  const [finalDocsUploaded, setFinalDocsUploaded] = useState<
    [string, string][]
  >([]);
  const submitHandler = () => {
    if (finalDocsUploaded.length > 0) {
      onClose();
      onSubmit(finalDocsUploaded);
    }
  };
  const finishDocs = useMemo(() => {
    if (docs) {
      const affidavit_image = docs.find(
        (item) => item.key === "affidavit_image"
      );
      const power_of_attorney_image = docs.find(
        (item) => item.key === "power_of_attorney_image"
      );

      return {
        affidavit_image,
        power_of_attorney_image
      };
    }

    return {
      affidavit_image: undefined,
      power_of_attorney_image: undefined
    };
  }, [docs]);

  useEffect(() => {
    if (docs) {
      // console.log({ docs });

      const affidavit_image = docs.find(
        (item) => item.key === "affidavit_image" && !!item.file_key
      );
      const power_of_attorney_image = docs.find(
        (item) => item.key === "power_of_attorney_image" && !!item.file_key
      );
      setFinalDocsUploaded((state) => {
        if (affidavit_image) {
          state[0] = ["affidavit_image", affidavit_image.file_key!];
        }
        if (power_of_attorney_image) {
          state[1] = [
            "power_of_attorney_image",
            power_of_attorney_image.file_key!
          ];
        }
        return state;
      });
    }
  }, [docs]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          height: "100vh",
          p: "20px"
        }}
      >
        <Stack
          sx={{
            padding: "1rem 0 2rem",
            width: "100%",
            alignItems: "center",
            position: "relative"
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "6px",
              left: 0
            }}
            onClick={() => {
              onClose();
            }}
          >
            <Close />
          </IconButton>
          <Typography>بارگذاری مدارک معامله</Typography>
        </Stack>
        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          height={"100%"}
        >
          <Stack gap={3}>
            <Stack gap={3}>
              <Typography fontSize={14}>
                لطفاً تصویر مبایعه‌نامه را بارگذاری نمایید:
              </Typography>
              <NewImageUploader
                value={finishDocs.affidavit_image?.url}
                title="بارگذاری تصویر مبایعه‌نامه"
                variant={["camera", "upload"]}
                onChange={(image) => {
                  console.log({ image });
                }}
                onUplaodSucced={(image) => {
                  setFinalDocsUploaded((state) => {
                    state[0] = ["affidavit_image", image?.key || ""];
                    return state;
                  });
                }}
              />
            </Stack>
            <Divider
              sx={{
                mx: "-20px"
              }}
            />
            <Stack gap={3}>
              <Typography fontSize={14}>
                لطفاً تصویر صفحات وکالت‌نامه را بارگذاری نمایید:
              </Typography>

              <NewImageUploader
                value={finishDocs.power_of_attorney_image?.url}
                title="بارگذاری تصویر وکالت‌نامه"
                variant={["camera", "upload"]}
                onChange={(image) => {
                  console.log({ image });
                }}
                onUplaodSucced={(image) => {
                  setFinalDocsUploaded((state) => {
                    state[1] = ["power_of_attorney_image", image?.key || ""];
                    return state;
                  });
                }}
              />
            </Stack>
          </Stack>
          <LoadingButton
            color={"primary"}
            loading={false}
            variant="contained"
            onClick={submitHandler}
            disabled={finalDocsUploaded.length < 2}
          >
            تایید و ارسال
          </LoadingButton>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
}

export default FinalDocs;
