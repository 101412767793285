import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { cityList } from "./data";
import { useFormik } from "formik";
import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import { usePostLeasingValidationSendOtp } from "@/services/api/leasing-order";
import { toEngDigits } from "@/utils/toEnglishDigit";
import CarSearchTextFeild from "@/components/CarSearchTextFeild";
import { Car } from "@/services/api/searchCar";
import SelectionPackage from "./SelectionPackage";
import { useDebounce } from "@/hooks/useDebounce";
import { toast } from "react-toastify";
import { useGetCities } from "@/services/api/leasing/city";
import { useNavigate } from "react-router";

const LeasingOrder: FC = () => {
  const { mutateAsync: register, isLoading } =
    usePostLeasingValidationSendOtp();

  const [selectedInstalmentState, setSelectedInstalmentState] =
    useState<GetLeasingInstalmentMonthsConfigItem | null>(null);
  const [selectesPackageState, setSelectesPackageState] =
    useState<GetProviderPackagesItem | null>(null);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      city: null as number | null,
      address: "",
      price: "",
      note: "",
      plan: null as number | null,
      year: null as string | null,
      national_id: null as string | null,
      selfAssign: true,
      car: null as Car | null
    },
    onSubmit: (values) => {
      if ((values.national_id?.length ?? 0) < 10) {
        toast.warn("طول کد ملی باید ۱۰ کاراکتر باشد.");
        return;
      }
      if (
        !!values.address &&
        typeof values.city === "number" &&
        typeof formik.values.year === "string" &&
        !!values.name &&
        /^09\d{9}$/.test(values.phone) &&
        selectesPackageState &&
        /^\d*$/.test(values.price) &&
        Number(values.price) >= 140_000_000
      ) {
        register({
          channel: "SA",
          customer: {
            full_name: values.name,
            national_id: values.national_id ?? "",
            phone_number: values.phone
          },
          income_limit: 0,
          instalment_month_count:
            selectedInstalmentState?.installment_month_count || 0,
          instalment_period:
            // eslint-disable-next-line no-unsafe-optional-chaining
            (selectesPackageState?.installment_month_counts.filter(
              (item) => !!item.period
            ))[0].period || 1,
          prepayment_amount: selectesPackageState?.prepayment || 0,
          provider_id: Number(selectesPackageState?.id),
          car_price: Number(values.price),
          address: values.address,
          city: values.city,
          operator_note: values.note,
          assign_to_me: values.selfAssign,
          car_brand_model: values.car?.name_en,
          car_manufacturer_year: values.year || "2024"
        })
          .then(() => {
            formik.resetForm();
            setSelectedInstalmentState(null);
            setSelectesPackageState(null);
            navigate(-1);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
          });
      }
    }
  });

  const isValid = useMemo(() => {
    return (
      !!formik.values.address &&
      typeof formik.values.city === "number" &&
      typeof formik.values.year === "string" &&
      !!formik.values.name &&
      /^09\d{9}$/.test(formik.values.phone) &&
      selectesPackageState &&
      /^\d*$/.test(formik.values.price) &&
      Number(formik.values.price) >= 140_000_000
    );
  }, [
    formik.values.address,
    formik.values.city,
    formik.values.name,
    formik.values.phone,
    formik.values.price,
    formik.values.year,
    selectesPackageState
  ]);

  const isValidForInstalment = useMemo(() => {
    return (
      /^\d*$/.test(formik.values.price) &&
      Number(formik.values.price) >= 140_000_000
    );
  }, [formik.values.price]);

  const numberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!event.target.name) {
        return;
      }
      let newValue = event.target.value;
      if (/[۰-۹]/.test(newValue)) {
        newValue = toEngDigits(newValue);
      }
      if (!/^[0-9]*$/.test(newValue)) {
        newValue = newValue.replace(/[^0-9]/g, "");
      }
      formik.setFieldValue(event.target.name, newValue);
    },
    [formik]
  );

  const manufacturedList = useMemo(() => {
    const currentDate = new Date();
    return new Array(currentDate.getFullYear() - 2001 + 1)
      .fill(null)
      .map((_, yearIndex) => {
        currentDate.setFullYear(2001 + yearIndex);
        const jalaliYear = currentDate
          .toLocaleDateString("fa-IR-u-nu-latn", { timeZone: "Iran" })
          .split("/")[0];
        return {
          georgian_value: currentDate.getFullYear(),
          value: String(jalaliYear),
          label: `${currentDate.getFullYear()} - ${jalaliYear}`
        };
      });
  }, []);

  const debouncedPrice = useDebounce(formik.values.price, 1000);

  const { data: citiesData, isLoading: cityLoading } = useGetCities();
  return (
    <Stack
      sx={{
        width: "100%",
        flexGrow: 1,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        overflow: "auto"
      }}
    >
      <Stack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          p: 2
        }}
      >
        <Typography fontSize="18px" fontWeight={600} mb={1}>
          ثبت درخواست لیزینگ
        </Typography>

        <TextField
          sx={{ mt: 2 }}
          size="small"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          fullWidth
          label="نام و نام خانوادگی"
        />

        <TextField
          sx={{ mt: 2 }}
          size="small"
          name="phone"
          value={formik.values.phone}
          onChange={numberChange}
          fullWidth
          label="شماره موبایل"
        />

        <TextField
          sx={{ mt: 2 }}
          size="small"
          name="national_id"
          value={formik.values.national_id}
          onChange={formik.handleChange}
          fullWidth
          helperText="طول کد ملی باید دقیقا ۱۰ کاراکتر باشد."
          label="کد ملی"
        />
        <FormControl size="small" sx={{ mt: 2 }} fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            شهر
          </InputLabel>
          <Select
            disabled={cityLoading}
            sx={{
              width: "100%"
            }}
            value={formik.values.city}
            label="شهر"
            size="small"
            name="city"
            onChange={formik.handleChange}
          >
            {citiesData?.data.map((cityItem) => {
              return (
                <MenuItem key={cityItem.id} value={cityItem.id}>
                  {cityItem.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          sx={{ my: 2 }}
          size="small"
          name="address"
          value={formik.values.address}
          onChange={formik.handleChange}
          fullWidth
          label="آدرس"
        />

        <CarSearchTextFeild
          initialValue={formik.values.car}
          serviceName="inspection"
          size="small"
          onChange={(data) => {
            formik.setFieldValue("car", data);
          }}
        />
        <FormControl size="small" fullWidth sx={{ mt: 2 }}>
          <InputLabel>سال ساخت</InputLabel>
          <Select
            label="سال ساخت"
            placeholder="انتخاب کنید"
            name="year"
            value={formik.values.year}
            onChange={formik.handleChange}
          >
            {manufacturedList
              .slice()
              .reverse()
              .map((item) => {
                return (
                  <MenuItem
                    key={item.value}
                    value={item.georgian_value.toString()}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <TextField
          sx={{ mt: 2 }}
          size="small"
          name="price"
          value={formik.values.price}
          onChange={numberChange}
          helperText={
            formik.values.price
              ? `${Number(formik.values.price).toLocaleString()} تومان`
              : "قیمت خودرو را وارد کنید"
          }
          fullWidth
          InputProps={{
            endAdornment: (
              <Typography
                color={(th) => th.palette.grey[600]}
                fontSize="0.75rem"
              >
                تومان
              </Typography>
            )
          }}
          label="قیمت خودرو"
        />

        <Typography mt={1} fontSize="0.75rem">
          مبلغ خودرو باید بالای 140 میلیون تومان باشد
        </Typography>

        <Typography fontSize="18px" fontWeight={600} mb={1} mt={3}>
          طرح‌ها
        </Typography>

        {isValidForInstalment && formik.values.price && formik.values.car ? (
          <SelectionPackage
            car_manufacturer_year={formik.values.year || "1403"}
            car_brand_model={formik.values.car.name_en}
            car_price={Number(debouncedPrice)}
            onChange={function (
              instalmentMonth: GetLeasingInstalmentMonthsConfigItem,
              selctedPackage: GetProviderPackagesItem
            ) {
              setSelectedInstalmentState(instalmentMonth);
              setSelectesPackageState(selctedPackage);
            }}
          />
        ) : (
          <Typography>لطفا اطلاعات بالا را تکمیل کنید</Typography>
        )}

        <TextField
          sx={{ mt: 3 }}
          size="small"
          name="note"
          multiline
          rows={3}
          value={formik.values.note}
          onChange={formik.handleChange}
          fullWidth
          label="یادداشت"
        />

        <FormControlLabel
          disabled
          onChange={(_, newChecked) => {
            formik.setFieldValue("selfAssign", newChecked);
          }}
          control={<Checkbox checked={formik.values.selfAssign} />}
          label="به خودم تخصیص داده شود."
        />

        <Button
          disabled={!isValid || isLoading}
          type="submit"
          fullWidth
          sx={{ mt: 2 }}
        >
          {isLoading ? <CircularProgress size={28} /> : "ثبت درخواست"}
        </Button>
      </Stack>
    </Stack>
  );
};

export default LeasingOrder;
