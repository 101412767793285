import {
  ChangeEvent,
  useCallback,
  useEffect,
  // useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { NewImageUploaderProps } from ".";
import { useUploadLeasingFile } from "@/services/api/leasing/requests";

const useLogic = (
  props: Pick<
    NewImageUploaderProps,
    "value" | "onChange" | "variant" | "onUplaodSucced"
  >,
  scope: "leasing-pose-payments" | "leasing-documents",
  onImageSelect?: (image: File | null) => void
) => {
  const { value, onChange, variant = "upload", onUplaodSucced } = props;

  const [valueState, setValueState] =
    useState<NewImageUploaderProps["value"]>();

  const inputRef = useRef<HTMLInputElement>(null);

  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const [isSelectVariantOpen, setIsSelectVariantOpen] = useState(false);

  const [uploadPerecentage, setUploadPerecentage] = useState(0);

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (typeof value === "string") {
      setValueState(value);
    }
    return () => {
      setValueState("");
    };
  }, [value]);

  const variantsList = useMemo(() => {
    return typeof variant === "string" ? [variant] : variant;
  }, [variant]);

  const { mutateAsync } = useUploadLeasingFile(({ progress }) => {
    if (progress) {
      setUploadPerecentage(Math.floor(progress * 100));
    }
  });

  const onChangeCaller = useCallback(
    async (newFile: File | null) => {
      if (typeof onImageSelect === "function") {
        onImageSelect(newFile);
      }
      if (typeof onChange === "function") {
        if (newFile) {
          const objUrl = URL.createObjectURL(newFile);
          setImageUrl(objUrl);
          onChange({
            url: objUrl,
            file: newFile
          });

          try {
            const res = await mutateAsync({
              file: newFile,
              scope
            });

            if (onUplaodSucced) onUplaodSucced(res.data);
          } catch (error) {
            console.error({ error });
          }
        } else {
          onChange(null);
        }
      }
    },
    [onChange, mutateAsync, scope, onUplaodSucced, onImageSelect]
  );

  // const imageUrl = useMemo(() => {
  //   console.log("--- inputRef.current?.files ---", inputRef.current?.files);

  //   if (!inputRef.current) return null;
  //   if (inputRef.current.files) {
  //     return inputRef.current.files[0]
  //       ? URL.createObjectURL(inputRef.current.files[0])
  //       : null;
  //   }
  //   return null;
  // }, [inputRef]);\

  const onInputChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        onChangeCaller(file);
      }
    },
    [onChangeCaller]
  );

  const onContainerClicked = useCallback(() => {
    if (!imageUrl) {
      if (valueState) {
        onChangeCaller(null);
      } else {
        if (
          (variant === "upload" ||
            (variantsList.length === 1 && variantsList[0] === "upload")) &&
          inputRef.current
        ) {
          inputRef.current.click();
        } else if (
          variant === "camera" ||
          (variantsList.length === 1 && variantsList[0] === "camera")
        ) {
          setIsCameraOpen(true);
        } else {
          setIsSelectVariantOpen(true);
        }
      }
    }
  }, [imageUrl, onChangeCaller, valueState, variant, variantsList]);

  const openCamera = useCallback(() => {
    setIsSelectVariantOpen(false);
    setIsCameraOpen(true);
  }, []);

  const openUpload = useCallback(() => {
    if (inputRef.current) {
      setIsSelectVariantOpen(false);
      inputRef.current.click();
    }
  }, []);

  const clearFile = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setValueState(undefined);
      setImageUrl(null);
      setIsSelectVariantOpen(false);
      setUploadPerecentage(0);
    }
  }, []);
  return {
    inputRef,
    isCameraOpen,
    setIsCameraOpen,
    isSelectVariantOpen,
    setIsSelectVariantOpen,
    variantsList,
    imageUrl,
    onInputChangeHandler,
    onContainerClicked,
    openCamera,
    openUpload,
    onChangeCaller,
    uploadPerecentage,
    clearFile,
    valueState
    // uploadedImage
  };
};

export default useLogic;
