import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import { FC, useState } from "react";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import {
  Documents,
  GetLeasingDetailResponse,
  usePutLeasingDetail
} from "@/services/api/leasing/detail";
import {
  useCustomerValidationSubmit,
  useGetLeasingStatus
} from "@/services/api/leasing/requests";
import { useParams } from "react-router";
import { DateModal } from "../dateModal";
import { toast } from "react-toastify";
import FinalDocs from "../finalDocs";
import RejectReasonModal from "../rejectReasonModal";
import { CustomerValidationModal } from "../customerValidationModal";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  reject?: string;
  date?: string;
  docs?: Documents;
  leasingOrder?: GetLeasingDetailResponse;
}

const StatusModal: FC<Props> = ({
  open,
  showModal,
  title,
  reject,
  date,
  docs,
  leasingOrder
}) => {
  const { id } = useParams();
  const [statusItem, setStatusItem] = useState("");
  const [contractDate, setContractDate] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [finalDocs, setFinalDocs] = useState<
    Record<string, string> | undefined
  >();
  const [isDateOpen, setDateOpen] = useState(false);
  const [rejectReasonModal, setRejectReasonModal] = useState(false);

  const { data: statusData } = useGetLeasingStatus();
  const [isCustomerValidationOpen, setCustomerValidationOpen] = useState(false);
  const [openFinalDocs, setOpenFinalDocs] = useState(false);
  const { mutateAsync, isLoading } = usePutLeasingDetail(id as string);
  const { mutateAsync: customerValidationSubmit } =
    useCustomerValidationSubmit();

  const statusList = Object.entries(statusData || {})?.map((item) => item);

  const submitHandler = () => {
    if (statusItem === "") {
      showModal(false);
      return;
    }
    const body = {
      status: statusItem as string
    } as {
      status: string;
      contract_date: string;
      documents: Record<string, string>;
    };
    if (statusItem === "waiting_for_contract") {
      if (!contractDate) {
        toast.warn(
          "وارد کردن تاریخ برای وضعیت «در انتظار تنظیم سند» اجباری است."
        );
        return;
      }
      body["contract_date"] = contractDate;
    }
    if (statusItem === "finish") {
      if (!finalDocs) {
        toast.warn("آپلود تصاویر وکالت نامه و مبایعه نامه اجباری است");
        return;
      }
      body["documents"] = finalDocs;
    }

    if (
      statusItem === "waiting_for_document" &&
      [84, 121, 122].includes(leasingOrder?.provider as unknown as number) &&
      Object.keys(leasingOrder?.validation_data || {}).length === 0
    ) {
      if (!birthDate) {
        toast.warn("وارد کردن تاریخ تولد اجباری است.");
        return;
      }

      customerValidationSubmit({
        order: Number(id),
        birthdate: birthDate
      })
        .then(() => {
          updateStatus(body);
        })
        .catch((err) => {
          toast.error(err.response.data.detail?.[0]);
          console.error({ err });
        });
    } else {
      updateStatus(body);
    }
  };

  const updateStatus = (body: {
    status: string;
    contract_date: string;
    documents: Record<string, string>;
  }) => {
    mutateAsync(body)
      .then(() => {
        showModal(false);
      })
      .catch((err) => {
        toast.error("تغییر وضعیت درخواست لیزینگ انجام نشد.");
        console.error({ err });
      })
      .finally(() => {
        setStatusItem("");
      });
  };

  const rejectModalHandler = () => {
    setStatusItem("");
    showModal(false);
    setRejectReasonModal(true);
  };

  return (
    <>
      <SwipeableDrawer
        disableSwipeToOpen
        disableDiscovery
        disableBackdropTransition
        anchor="bottom"
        open={open}
        onClose={() => showModal(false)}
        onOpen={() => showModal(true)}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <Stack
          sx={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            background: "#fff",
            p: "20px"
          }}
        >
          <Box sx={styles.modalTitleArea}>
            <Typography fontWeight={600} fontSize={14}>
              {title}
            </Typography>
            <CloseIcon onClick={() => showModal(false)} />
          </Box>
          <Stack sx={{ flexDirection: "column" }}>
            <Stack
              sx={{
                flexDirection: "column",
                // flexWrap: "wrap",
                justifyContent: "space-between",
                maxHeight: "65dvh",
                overflowY: "auto",
                padding: "0.5em"
              }}
            >
              {statusList.map((item) => (
                <Stack
                  key={item[0]}
                  sx={{
                    background: (function () {
                      if (item[0] === statusItem) {
                        switch (statusItem) {
                          case "pre_order":
                          case "initial_order":
                            return "#E5F8FC";
                          case "waiting_for_document":
                            return "#FFE8F7";
                          case "waiting_for_physical_delivery":
                            return "#E5F8FC";
                          case "document_review":
                            return "#FDEBDC";
                          case "loan_reject":
                            return "#FFF4F4";
                          case "waiting_for_affidavit_preparation":
                            return "#F2EBFF";
                          case "send_to_leasing":
                            return "#F0E9DD";
                          case "finish":
                            return "#EBF8F1";
                          default:
                            return "#A9A8AA";
                        }
                      } else {
                        return "#d5d5d550";
                      }
                    })(),
                    color: (function () {
                      if (item[0] === statusItem) {
                        switch (statusItem) {
                          case "pre_order":
                          case "initial_order":
                            return "#107BC1";
                          case "waiting_for_document":
                            return "#FC00A3";
                          case "waiting_for_physical_delivery":
                            return "#27C0E3";
                          case "document_review":
                            return "#F4770D";
                          case "loan_reject":
                            return "#CD0000";
                          case "waiting_for_affidavit_preparation":
                            return "#6514FF";
                          case "send_to_leasing":
                            return "#836838";
                          case "finish":
                            return "#2EB974";
                          default:
                            return "#5a5a5a90";
                        }
                      } else {
                        return "#68686880";
                      }
                    })(),
                    mb: 1.75,
                    borderRadius: 25,
                    justifyContent: "center",
                    alignItems: "center",
                    py: 0.75
                  }}
                  onClick={() => {
                    setStatusItem(item[0]);
                    if (item[0] === "waiting_for_contract") {
                      setDateOpen(true);
                    }
                    if (
                      item[0] === "waiting_for_document" &&
                      [84, 121, 122].includes(
                        leasingOrder?.provider as unknown as number
                      ) &&
                      Object.keys(leasingOrder?.validation_data || {})
                        .length === 0
                    ) {
                      setCustomerValidationOpen(true);
                    }
                    if (item[0] === "finish") {
                      setOpenFinalDocs(true);
                    }
                  }}
                >
                  {item[1]}
                </Stack>
              ))}
            </Stack>

            <Button sx={{ mt: "1rem" }} onClick={submitHandler} size="medium">
              {isLoading ? (
                <CircularProgress size={20} sx={{ color: "#00000050" }} />
              ) : (
                "ثبت تغییرات"
              )}
            </Button>

            {reject && (
              <Button
                sx={{ mt: "1rem", backgroundColor: "red" }}
                onClick={rejectModalHandler}
                size="medium"
              >
                {isLoading ? (
                  <CircularProgress size={20} sx={{ color: "#00000050" }} />
                ) : (
                  reject
                )}
              </Button>
            )}
          </Stack>
        </Stack>
      </SwipeableDrawer>
      <DateModal
        open={isDateOpen}
        showModal={setDateOpen}
        onSubmit={(val) => {
          setContractDate(val);
        }}
        dateValue={date}
      />
      <CustomerValidationModal
        open={isCustomerValidationOpen}
        showModal={setCustomerValidationOpen}
        onSubmit={(val) => {
          setBirthDate(val);
        }}
      />
      <FinalDocs
        docs={docs?.order_documents || []}
        open={openFinalDocs}
        onClose={() => setOpenFinalDocs(false)}
        onOpen={() => setOpenFinalDocs(true)}
        onSubmit={(data) => {
          setFinalDocs(Object.fromEntries(data));
        }}
      />
      <RejectReasonModal
        openModal={rejectReasonModal}
        setOpenModal={setRejectReasonModal}
      />
    </>
  );
};

export default StatusModal;
