import { useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import {
  ErDocument,
  GetLeasingDetailResponse,
  usePutLeasingDetail
} from "@/services/api/leasing/detail";
import { useNavigate, useParams } from "react-router";
import { useGetLeasingStatus } from "@/services/api/leasing/requests";
import ContactModal from "@/pages/leasing/request-detail/components/contactModal";
import StatusModal from "@/pages/leasing/request-detail/components/statusModal";
import TextFieldModal from "../../components/textFieldModal";
import CarPriceModal from "@/pages/leasing/request-detail/components/carPriceModal";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { useGetSendUploadDocsSms } from "@/services/api/leasing";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import useCopyToClipboard from "@/hooks/useCopyToClipboard";
import PhotosModal from "@/pages/leasing/request-detail/components/photosModal";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { DateModal } from "../../components/dateModal";
import NameModal from "../../components/nameModal";
import NationalCodeModal from "../../components/nationalCodeModal";
import ChangePackageModal from "../../components/changePackageModal";

const stateMap = [
  "loan_reject", // 7
  "initial_order", // 0
  "pre_order", // 0
  "waiting_for_document", // 1
  "document_review", // 2
  "waiting_for_physical_delivery", // 3
  "send_to_leasing", // 4
  "waiting_for_affidavit_preparation", // 5
  "finish" // 6
];

type Props = {
  leasingData: GetLeasingDetailResponse;
};

const LeasingRequestInfo = ({ leasingData }: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isDateOpen, setDateOpen] = useState(false);
  const [isContactModal, setIsContactModal] = useState(false);
  const [isStatusModal, setIsStatusModal] = useState(false);
  const [isCarPriceModal, setIsCarPriceModal] = useState(false);
  const [isAddressModal, setIsAddressModal] = useState(false);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isPhotoModal, setIsPhotoModal] = useState(false);
  const [isNationalCodeModal, setIsNationalCodeModal] = useState(false);
  const [isPackageModal, setIsPackageModal] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [toast, setToast] = useState(false);
  const [showCopyLink, setShowCopyLink] = useState(false);
  const [isNameModal, setIsNameModal] = useState(false);

  const [_, copy] = useCopyToClipboard();

  const { mutateAsync: sendSms } = useGetSendUploadDocsSms();
  const { mutateAsync: editOrder, isLoading: editOrderLoading } =
    usePutLeasingDetail(id as string);

  const { data: statusData } = useGetLeasingStatus();

  const statusList = Object.entries(statusData || {})?.map((item) => item);

  const copyToClipBoard = async (data: string) => {
    await navigator.clipboard.writeText(data).then(() => setToast(true));
  };

  const documentUrls = useMemo(() => {
    if (!leasingData) return [];
    const urls: ErDocument[] = [];
    leasingData.documents.customer_documents.forEach((item) => {
      if (item.url) urls.push(item);
    });
    leasingData.documents.seller_documents.forEach((item) => {
      if (item.url) urls.push(item);
    });
    leasingData.documents.order_documents.forEach((item) => {
      if (item.url) urls.push(item);
    });
    return urls;
  }, [leasingData]);

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          height: "100vh",
          overflowY: "scroll",
          background: "#FAFAFA"
        }}
      >
        <Stack
          sx={{
            background: "#fff",
            borderBottom: "1px solid #EBEBEB",
            px: 3,
            py: 2,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Typography
              sx={{ fontWeight: 500, color: "#424242", fontSize: 17 }}
            >
              {leasingData?.customer?.full_name}
            </Typography>
            <IconButton onClick={() => setIsNameModal(true)}>
              <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
            </IconButton>
          </Stack>
          <Button onClick={() => setIsContactModal(true)}>
            <PhoneEnabledIcon sx={{ fontSize: 22, mr: 0.5 }} />
            تماس
          </Button>
        </Stack>

        <Stack
          sx={{
            background: "#fff",
            borderTop: "1px solid #EBEBEB",
            borderBottom: "1px solid #EBEBEB",
            my: 1.5,
            px: 3,
            py: 2,
            color: "#202021"
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: 16 }}>
                {leasingData?.id}
              </Typography>
              <IconButton onClick={() => copyToClipBoard(id as string)}>
                <ContentCopyIcon sx={{ color: "#6E6E6E", fontSize: 18 }} />
              </IconButton>
            </Stack>
            {leasingData?.parent_id && (
              <Stack
                sx={{
                  bgcolor: "#694FB4",
                  color: "white",
                  borderRadius: 25,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 0.75,
                  pr: 2,
                  pl: 3
                }}
              >
                <Typography sx={{ fontSize: 14 }}>
                  ترکیبی ({leasingData?.parent_id})
                </Typography>
              </Stack>
            )}
            {!leasingData?.is_rejected ? (
              <Stack
                sx={{
                  background: (function () {
                    switch (leasingData?.status) {
                      case "pre_order":
                      case "initial_order": // 0
                        return "#E5F8FC";
                      case "waiting_for_document": // 1
                        return "#FFE8F7";
                      case "waiting_for_physical_delivery": // 3
                        return "#E5F8FC";
                      case "document_review": // 2
                        return "#FDEBDC";
                      case "loan_reject": // 7
                        return "#FFF4F4";
                      case "waiting_for_affidavit_preparation": // 5
                        return "#F2EBFF";
                      case "send_to_leasing": // 4
                        return "#F0E9DD";
                      case "finish": // 6
                        return "#EBF8F1";
                      default:
                        return "#A9A8AA";
                    }
                  })(),
                  color: (function () {
                    switch (leasingData?.status) {
                      case "pre_order":
                      case "initial_order":
                        return "#107BC1";
                      case "waiting_for_document":
                        return "#FC00A3";
                      case "waiting_for_physical_delivery":
                        return "#27C0E3";
                      case "document_review":
                        return "#F4770D";
                      case "loan_reject":
                        return "#CD0000";
                      case "waiting_for_affidavit_preparation":
                        return "#6514FF";
                      case "send_to_leasing":
                        return "#836838";
                      case "finish":
                        return "#2EB974";
                      default:
                        return "#5a5a5a90";
                    }
                  })(),
                  borderRadius: 25,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 0.75,
                  pr: 2,
                  pl: 3
                }}
                onClick={() => setIsStatusModal(true)}
              >
                <Typography sx={{ fontSize: 14 }}>
                  {statusList.find(
                    (st) => st[0] === leasingData?.status
                  )?.[1] || "-"}
                </Typography>
                <ArrowDropDownIcon sx={{ fontSize: 20 }} />
              </Stack>
            ) : (
              <Stack
                sx={{
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: 25,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 0.75,
                  pr: 2,
                  pl: 3
                }}
              >
                <Typography sx={{ fontSize: 14 }}>لغو شده</Typography>
              </Stack>
            )}
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              توکن درخواست
            </Typography>
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>{leasingData?.uuid}</Typography>
              <IconButton
                onClick={() =>
                  copy(leasingData?.uuid as string).then(() => {
                    setToast(true);
                  })
                }
                size="small"
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    color: (th) => th.palette.grey[600],
                    width: "18px",
                    height: "18px"
                  }}
                />
              </IconButton>
              {/*
            <IconButton>
              <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
            </IconButton> */}
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              تاریخ ثبت درخواست
            </Typography>
            <Typography sx={{ fontSize: 15 }}>
              {leasingData?.created_at
                ? new Date(
                    leasingData?.created_at as string
                  ).toLocaleDateString("fa", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                  })
                : "-"}
            </Typography>
          </Stack>
          {leasingData?.contract_date && (
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #EBEBEB",
                pb: 1.5,
                mt: 1.5
              }}
            >
              <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
                تاریخ تنظیم سند
              </Typography>

              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ fontSize: 15 }}>
                  {leasingData?.contract_date
                    ? `${new Date(leasingData.contract_date).toLocaleDateString(
                        "fa-IR"
                      )}`
                    : "- - - - -"}
                </Typography>
                {leasingData?.status === "waiting_for_contract" && (
                  <IconButton
                    onClick={() => {
                      setDateOpen(true);
                    }}
                  >
                    <CreateOutlinedIcon
                      sx={{ fontSize: 18, color: "#6E6E6E" }}
                    />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          )}

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              مبلغ خودرو
            </Typography>
            {leasingData?.car_price ? (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ fontSize: 15 }}>
                  {Number(leasingData?.car_price).toLocaleString()}
                </Typography>
                <Typography sx={{ fontSize: 12, ml: 0.5, color: "#6E6E6E" }}>
                  تومان
                </Typography>
                <IconButton onClick={() => setIsCarPriceModal(true)}>
                  <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
                </IconButton>
              </Stack>
            ) : (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography>-</Typography>
                <IconButton onClick={() => setIsCarPriceModal(true)}>
                  <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
                </IconButton>
              </Stack>
            )}
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              شماره ملی
            </Typography>

            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>
                {leasingData?.customer?.national_code}
              </Typography>

              <IconButton onClick={() => setIsNationalCodeModal(true)}>
                <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
              </IconButton>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              تامین‌کننده و مدت بازپرداخت{" "}
            </Typography>
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>
                {leasingData?.provider_name_fa && leasingData?.provider_name_fa}{" "}
                -{" "}
                {leasingData?.instalment_month_count
                  ? `${leasingData.instalment_month_count} ماهه`
                  : "-"}
              </Typography>
              <IconButton onClick={() => setIsPackageModal(true)}>
                <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
              </IconButton>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              مبلغ بیعانه
            </Typography>

            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>
                {leasingData?.deposit_amount
                  ? `${leasingData.deposit_amount.toLocaleString()}`
                  : "- - - - -"}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              اعتبار کیف پول
            </Typography>

            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>
                {leasingData?.wallet_amount
                  ? `${leasingData.wallet_amount.toLocaleString()} تومان`
                  : "- - - - -"}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              نوع تسهیلات
            </Typography>

            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>
                {leasingData?.provider?.name_fa || "-"}
              </Typography>
            </Stack>
          </Stack>

          {/* <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              مبلغ وام{" "}
            </Typography>
            {data?.amount ? (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ fontSize: 15 }}>
                  {Number(data?.amount).toLocaleString()}
                </Typography>
                <Typography sx={{ fontSize: 12, ml: 0.5, color: "#6E6E6E" }}>
                  تومان
                </Typography>
              </Stack>
            ) : (
              "-"
            )}
          </Stack> */}

          {typeof leasingData !== "undefined" &&
            typeof leasingData.car_post_url === "string" && (
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #EBEBEB",
                  pb: 1.5,
                  mt: 1.5
                }}
              >
                <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
                  لینک آگهی خودرو
                </Typography>

                <Stack
                  sx={{ flexDirection: "row", alignItems: "center" }}
                  gap={1}
                >
                  <IconButton
                    onClick={() =>
                      window.open(leasingData.car_post_url as string, "_parent")
                    }
                    size="small"
                  >
                    <RemoveRedEyeOutlinedIcon
                      sx={{
                        color: (th) => th.palette.grey[600],
                        width: "18px",
                        height: "18px"
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      copy(leasingData.car_post_url as string).then(() => {
                        setShowCopyLink(true);
                      })
                    }
                    size="small"
                  >
                    <ContentCopyOutlinedIcon
                      sx={{
                        color: (th) => th.palette.grey[600],
                        width: "18px",
                        height: "18px"
                      }}
                    />
                  </IconButton>
                  <Snackbar
                    autoHideDuration={3000}
                    ContentProps={{
                      sx: {
                        background: "#00a754"
                      }
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={showCopyLink}
                    onClose={() => setShowCopyLink(false)}
                    message="لینک آگهی پرداخت کپی شد."
                  />
                </Stack>
              </Stack>
            )}

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              آدرس
            </Typography>

            {leasingData?.address ? (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ fontSize: 15, mx: 2, lineHeight: "1.7" }}>
                  {leasingData.address}
                </Typography>
                <IconButton onClick={() => setIsAddressModal(true)}>
                  <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
                </IconButton>
              </Stack>
            ) : (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography>-</Typography>
                <IconButton onClick={() => setIsAddressModal(true)}>
                  <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack
          sx={{
            background: "#fff",
            borderTop: "1px solid #EBEBEB",
            borderBottom: "1px solid #EBEBEB",
            px: 3,
            py: 2,
            color: "#202021"
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5
            }}
          >
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    stateMap.indexOf(leasingData?.status || "") >=
                    stateMap.indexOf("initial_order")
                      ? "#E0F4EA"
                      : "#EEEEEE",
                  mr: 1
                }}
              >
                <DoneRoundedIcon
                  sx={{
                    width: "18px",
                    height: "18px",
                    color: "#00A754",
                    opacity:
                      stateMap.indexOf(leasingData?.status || "") >
                      stateMap.indexOf("initial_order")
                        ? 1
                        : 0
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
                ثبت درخواست
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              mt: 0.5
            }}
          >
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    stateMap.indexOf(leasingData?.status || "") >=
                    stateMap.indexOf("waiting_for_document")
                      ? "#E0F4EA"
                      : "#EEEEEE",
                  mr: 1
                }}
              >
                <DoneRoundedIcon
                  sx={{
                    width: "18px",
                    height: "18px",
                    color: "#00A754",
                    opacity:
                      stateMap.indexOf(leasingData?.status || "") >
                      stateMap.indexOf("waiting_for_document")
                        ? 1
                        : 0
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
                تصویر مدارک
              </Typography>
            </Stack>
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              {documentUrls.length ? (
                <>
                  <IconButton onClick={() => setIsPhotoModal(true)}>
                    <DownloadIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </>
              ) : null}

              {typeof leasingData !== "undefined" && (
                <IconButton
                  onClick={() => {
                    sendSms({ leasingOrderID: leasingData.id }).then(() => {
                      setSmsSent(true);
                    });
                  }}
                >
                  <EmailIcon sx={{ fontSize: 20 }} />
                </IconButton>
              )}
              <Snackbar
                autoHideDuration={3000}
                ContentProps={{
                  sx: {
                    background: "#00a754"
                  }
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={smsSent}
                onClose={() => setSmsSent(false)}
                message="پیامک ارسال شد"
              />
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    stateMap.indexOf(leasingData?.status || "") >=
                    stateMap.indexOf("document_review")
                      ? "#E0F4EA"
                      : "#EEEEEE",
                  mr: 1
                }}
              >
                <DoneRoundedIcon
                  sx={{
                    width: "18px",
                    height: "18px",
                    color: "#00A754",
                    opacity:
                      stateMap.indexOf(leasingData?.status || "") >
                      stateMap.indexOf("document_review")
                        ? 1
                        : 0
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
                بررسی مدارک
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    stateMap.indexOf(leasingData?.status || "") >=
                    stateMap.indexOf("waiting_for_physical_delivery")
                      ? "#E0F4EA"
                      : "#EEEEEE",
                  mr: 1
                }}
              >
                <DoneRoundedIcon
                  sx={{
                    width: "18px",
                    height: "18px",
                    color: "#00A754",
                    opacity:
                      stateMap.indexOf(leasingData?.status || "") >
                      stateMap.indexOf("waiting_for_physical_delivery")
                        ? 1
                        : 0
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
                تحویل فیزیکی مدارک
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    stateMap.indexOf(leasingData?.status || "") >=
                    stateMap.indexOf("send_to_leasing")
                      ? "#E0F4EA"
                      : "#EEEEEE",
                  mr: 1
                }}
              >
                <DoneRoundedIcon
                  sx={{
                    width: "18px",
                    height: "18px",
                    color: "#00A754",
                    opacity:
                      stateMap.indexOf(leasingData?.status || "") >
                      stateMap.indexOf("send_to_leasing")
                        ? 1
                        : 0
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
                تأیید تأمین‌کننده
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1.5,
              pb: 1.5,
              borderBottom: "1px solid #EBEBEB"
            }}
            onClick={() => navigate(`/leasing/requests/${id}/payment`)}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    stateMap.indexOf(leasingData?.status || "") >=
                    stateMap.indexOf("waiting_for_affidavit_preparation")
                      ? "#E0F4EA"
                      : "#EEEEEE",
                  mr: 1
                }}
              >
                <DoneRoundedIcon
                  sx={{
                    width: "18px",
                    height: "18px",
                    color: "#00A754",
                    opacity:
                      stateMap.indexOf(leasingData?.status || "") >
                      stateMap.indexOf("waiting_for_affidavit_preparation")
                        ? 1
                        : 0
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
                پرداخت ها
              </Typography>
            </Stack>

            <ChevronLeftIcon sx={{ color: "#6E6E6E" }} />
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1.5
            }}
            onClick={() => {
              if (!leasingData?.inspection_token) {
                navigate(`/leasing/requests/${id}/submit-inspection`);
              }
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    stateMap.indexOf(leasingData?.status || "") >=
                    stateMap.indexOf("waiting_for_affidavit_preparation")
                      ? "#E0F4EA"
                      : "#EEEEEE",
                  mr: 1
                }}
              >
                <DoneRoundedIcon
                  sx={{
                    width: "18px",
                    height: "18px",
                    color: "#00A754",
                    opacity: !leasingData?.inspection_token ? 0 : 1
                  }}
                />
              </Box>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                style={{ width: "100%" }}
              >
                <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
                  درخواست کارشناسی
                </Typography>
                {leasingData?.inspection_token && (
                  <Typography
                    sx={{ fontSize: 14, color: "#6E6E6E", fontWeight: "bold" }}
                  >
                    {leasingData?.inspection_token}
                  </Typography>
                )}
              </Stack>
            </Stack>

            {!leasingData?.inspection_token && (
              <ChevronLeftIcon sx={{ color: "#6E6E6E" }} />
            )}
          </Stack>
        </Stack>
      </Stack>
      <ContactModal
        open={isContactModal}
        showModal={setIsContactModal}
        fullName={(leasingData?.customer?.full_name as string) || "-"}
        phoneNumber={(leasingData?.customer?.phone_number as string) || "-"}
      />
      <StatusModal
        leasingOrder={leasingData}
        open={isStatusModal}
        showModal={setIsStatusModal}
        title="انتخاب وضعیت"
        reject="لغو درخواست"
        date={leasingData?.contract_date}
        docs={leasingData?.documents}
      />
      <TextFieldModal
        open={isAddressModal}
        showModal={setIsAddressModal}
        title="ویرایش آدرس"
        value={leasingData?.address as string}
        isTextArea
        isLoading={editOrderLoading}
        onSubmit={(value) => {
          editOrder({
            address: value as string
          }).then(() => {
            setIsAddressModal(false);
          });
        }}
      />
      <TextFieldModal
        open={isDepositModalOpen}
        showModal={setIsDepositModalOpen}
        title="ویرایش مبلغ بیعانه"
        value={
          leasingData?.deposit_amount
            ? leasingData.deposit_amount.toString()
            : ""
        }
        inputType="numeric"
        onSubmit={(value) => {
          editOrder({
            deposit_amount: value as string
          }).then(() => {
            setIsDepositModalOpen(false);
          });
        }}
      />
      <CarPriceModal
        open={isCarPriceModal}
        showModal={setIsCarPriceModal}
        title="ویرایش مبلغ خودرو"
        carPrice={leasingData?.car_price as string}
      />
      <PhotosModal
        open={isPhotoModal}
        showModal={setIsPhotoModal}
        title="دانلود مدارک"
        documents={documentUrls}
      />
      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: "#00a754"
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toast}
        onClose={() => setToast(false)}
        message="آیدی با موفقیت کپی شد"
      />
      <DateModal
        onSubmit={(val) => {
          editOrder({ contract_date: val });
        }}
        open={isDateOpen}
        showModal={setDateOpen}
        dateValue={leasingData?.contract_date?.split("T")[0]}
      />
      <NameModal
        open={isNameModal}
        showModal={setIsNameModal}
        title="ویرایش نام"
        firstName={leasingData?.customer?.first_name}
        lastName={leasingData?.customer?.last_name}
      />
      <NationalCodeModal
        open={isNationalCodeModal}
        showModal={setIsNationalCodeModal}
        title="ویرایش کدملی"
        nationalCode={leasingData?.customer?.national_code}
      />
      <ChangePackageModal
        open={isPackageModal}
        showModal={setIsPackageModal}
        title="ویرایش مبلغ، تامین کننده و مدت بازپرداخت"
        installmentCoefficientData={leasingData?.installment_coefficient_data}
        providerId={Number(leasingData?.provider)}
      />
    </>
  );
};

export default LeasingRequestInfo;
